import sliderBoxesAlterarSlider from "./sliderBoxesAlterarSlider";
import sliderBoxAtualizaBullets from "./sliderBoxesAtualizaBullets";

var loop: any;

const setSliderLoop = () => {
    const slidePrincipalTempoElemento: HTMLElement | null = document.querySelector('.slider-boxes__slide--principal');
    const slidePrincipalTempo: string = slidePrincipalTempoElemento?.dataset.transicaoSlide || '10';

    const slideSegundoTempoElemento: HTMLElement | null = document.querySelector('.slider-boxes__slide--segundo');
    const slideSegundoTempo: string = slideSegundoTempoElemento?.dataset.transicaoSlide || '10';

    const slideTerceiroTempoElemento: HTMLElement | null = document.querySelector('.slider-boxes__slide--terceiro');
    const slideTerceiroTempo: string = slideTerceiroTempoElemento?.dataset.transicaoSlide || '10';

    let valoresTempos: Array<number> = [];

    valoresTempos.push(parseInt(slidePrincipalTempo));
    valoresTempos.push(parseInt(slideSegundoTempo));
    valoresTempos.push(parseInt(slideTerceiroTempo));

    let tempoAtual = (valoresTempos[0] * 1000);

    let cont = 0;
    loop = setTimeout(function acao() {
        let slidePrincipal: HTMLElement | null = document.querySelector('.slider-boxes__slide--principal');
        let slideSegundo: HTMLElement | null = document.querySelector('.slider-boxes__slide--segundo');
        let slideTerceiro: HTMLElement | null = document.querySelector('.slider-boxes__slide--terceiro');

        slidePrincipal?.classList.add('slider-boxes__slide--terceiro');
        slidePrincipal?.classList.remove('slider-boxes__slide--principal');

        slideSegundo?.classList.add('slider-boxes__slide--principal');
        slideSegundo?.classList.remove('slider-boxes__slide--segundo');

        slideTerceiro?.classList.add('slider-boxes__slide--segundo');
        slideTerceiro?.classList.remove('slider-boxes__slide--terceiro');

        sliderBoxAtualizaBullets();

        document.querySelectorAll('.slider-boxes__slide--principal .slider-boxes__video')
            .forEach((video: any) => {
                video.currentTime = 0;
                video.play();
            }
            );

        cont++;
        if (cont > 2) { cont = 0; }
        tempoAtual = ((valoresTempos[cont] + .1) * 1000);
        loop = setTimeout(acao, tempoAtual);
    }, tempoAtual);
}

const definirSlide = (slide: string | number | undefined) => {
    clearTimeout(loop);
    sliderBoxesAlterarSlider(slide);
    setSliderLoop();
}

const sliderBoxesIniciar = () => {
    window.addEventListener("load", () => {
        setSliderLoop();

        const controleAnterior = <HTMLElement>document.querySelector('.slider-boxes__controle--anterior');
        const controleProximo = <HTMLElement>document.querySelector('.slider-boxes__controle--proximo');
        const bullets = <NodeListOf<HTMLElement>>document.querySelectorAll('.slider-boxes__bullet');
        const slides = <NodeListOf<HTMLElement>>document.querySelectorAll('.slider-boxes__slide a');
        const sliderBox = <HTMLElement>document.querySelector('.slider-boxes');

        controleAnterior.addEventListener('click', () => definirSlide('anterior'));
        controleProximo.addEventListener('click', () => definirSlide('proximo'));

        bullets.forEach((bullet: HTMLElement) => bullet
            .addEventListener('click', () => definirSlide(bullet.dataset.slideAlvo)));

        slides.forEach((slide: HTMLElement) => slide
            .addEventListener('click', (evento: Event) => {
                let ancoraSlideClicado = <HTMLElement>evento.target;
                let slideClicado = <HTMLElement>ancoraSlideClicado.parentElement;

                if (!slideClicado.classList.contains('slider-boxes__slide--principal')) {
                    evento.preventDefault();
                    definirSlide(slideClicado.dataset.numeroSlide);
                }
            }));

        var startX: any;
        sliderBox.addEventListener('touchstart', (evento: any) => {
            if (evento.touches && evento.touches.length === 1) {
                const touch = evento.touches[0]
                startX = touch.clientX
            }
        });

        sliderBox.addEventListener('touchend', (evento: any) => {
            const swipeLimit = 100;
            const finishingTouch = evento.changedTouches[0].clientX;

            let slideAtivo: HTMLElement = <HTMLElement>document.querySelector('.slider-boxes__slide--principal');
            let numeroSlideAtivoString: string = slideAtivo.dataset.numeroSlide || '0';
            let numeroSlideAtivo: number = parseInt(numeroSlideAtivoString);

            if (startX < (finishingTouch - swipeLimit)) {
                if (numeroSlideAtivo == 0) {
                    definirSlide(2);
                } else {
                    definirSlide(numeroSlideAtivo - 1);
                }
            } else if (startX > (finishingTouch + swipeLimit)) {
                if (numeroSlideAtivo == 2) {
                    definirSlide(0);
                } else {
                    definirSlide(numeroSlideAtivo + 1);
                }
            }
        });
    });
}

export default sliderBoxesIniciar;
import { CreateAjax } from "../../Utils/createAjax";
import { IncluirOptionSelect } from "../CalculadoraEnem/IncluirOptionSelect";

export const BuscaPorPoloFormulario = () => {
  document.querySelector('#busca-por-polos__select--pais')?.addEventListener('change', () => {
    let pais = <HTMLInputElement>document.querySelector('#busca-por-polos__select--pais');
    let paisValor = pais.value.toString();

    let nonce = <HTMLInputElement>document.querySelector('#busca-por-polos__action--get-estados');
    let nonceValor = nonce.value.toString();

    let loader = pais?.parentElement?.nextElementSibling;
    let estadoGrupo = document.querySelector('#busca-por-polos__select--estado')?.parentElement;
    let cidade = <HTMLSelectElement>document.querySelector('#busca-por-polos__select--cidade');
    let polo = <HTMLSelectElement>document.querySelector('#busca-por-polos__select--polo');

    loader?.classList.add('busca-por-polos__loader--ativo');
    estadoGrupo?.classList.add('busca-por-polos__select--oculto');

    let dados = new FormData();
    dados.append('action', 'getEstados');
    dados.append('pais', paisValor);
    dados.append('nonce', nonceValor);

    CreateAjax(dados)
      .then((retorno) => {
        IncluirOptionSelect(
          document.querySelector('#busca-por-polos__select--estado'),
          retorno,
          'sigla',
          'nome',
          'busca-por-polos__grupo-campo--desabilitado'
        );

        cidade.value = '';
        polo.value = '';

        buscaPorPoloValidacaoCampos();

        setTimeout(() => {
          loader?.classList.remove('busca-por-polos__loader--ativo');
          estadoGrupo?.classList.remove('busca-por-polos__select--oculto');
        }, 1000);
      });

  });

  document.querySelector('#busca-por-polos__select--estado')?.addEventListener('change', () => {
    let estado = <HTMLInputElement>document.querySelector('#busca-por-polos__select--estado');
    let loader = estado?.parentElement?.nextElementSibling;
    let cidadeGrupo = document.querySelector('#busca-por-polos__select--cidade')?.parentElement;
    let nonce = <HTMLInputElement>document.querySelector('#busca-por-polos__action--get-cidades');
    let polo = <HTMLSelectElement>document.querySelector('#busca-por-polos__select--polo');

    loader?.classList.add('busca-por-polos__loader--ativo');
    cidadeGrupo?.classList.add('busca-por-polos__select--oculto');

    let dados = new FormData();
    dados.append('action', 'getCidades');
    dados.append('estado', estado.value);
    dados.append('nonce', nonce.value);

    CreateAjax(dados)
      .then((retorno) => {
        IncluirOptionSelect(
          document.querySelector('#busca-por-polos__select--cidade'),
          retorno,
          'coordenadas',
          'nome',
          'busca-por-polos__grupo-campo--desabilitado'
        );

        polo.value = '';

        buscaPorPoloValidacaoCampos();

        setTimeout(() => {
          loader?.classList.remove('busca-por-polos__loader--ativo');
          cidadeGrupo?.classList.remove('busca-por-polos__select--oculto');
        }, 1000);
      });
  });

  document.querySelector('#busca-por-polos__select--cidade')?.addEventListener('change', () => {
    let cidade = <HTMLInputElement>document.querySelector('#busca-por-polos__select--cidade');
    let loader = cidade?.parentElement?.nextElementSibling;
    let poloGrupo = document.querySelector('#busca-por-polos__select--polo')?.parentElement;
    let nonce = <HTMLInputElement>document.querySelector('#busca-por-polos__action--get-polos-geral');

    loader?.classList.add('busca-por-polos__loader--ativo');
    poloGrupo?.classList.add('busca-por-polos__select--oculto');

    let dados = new FormData();
    dados.append('action', 'getPolosGeral');
    dados.append('coordenadas', cidade.value);
    dados.append('nonce', nonce.value);

    CreateAjax(dados)
      .then((retorno) => {
        IncluirOptionSelect(
          document.querySelector('#busca-por-polos__select--polo'),
          retorno,
          'slug',
          'nome',
          'busca-por-polos__grupo-campo--desabilitado'
        );

        buscaPorPoloValidacaoCampos();

        setTimeout(() => {
          loader?.classList.remove('busca-por-polos__loader--ativo');
          poloGrupo?.classList.remove('busca-por-polos__select--oculto');
        }, 1000);
      });

  });

  document.querySelector('#busca-por-polos__select--polo')?.addEventListener('change', () => {
    let poloSelecionado = <HTMLSelectElement>document.querySelector('#busca-por-polos__select--polo');

    document.querySelector('.busca-por-polos__botoes')?.classList.remove('busca-por-polos__botoes--oculto');
    document.querySelector('.busca-por-polos__botao--primario')?.setAttribute(
      'href',
      window.location.protocol + '//' + window.location.host + '/polo/' + poloSelecionado.value
    );

    buscaPorPoloValidacaoCampos();
  });

  document.querySelector('#busca-por-polos__geolocalizacao')?.addEventListener('change', () => {

    if (!navigator.geolocation) {
      alert("A geo localização não é suportada pelo seu navegador.")
      return;
    };

    navigator.geolocation.getCurrentPosition(buscaPorPolosGeolocalizacao);
    return;
  });

  document.querySelector('.busca-por-polos__botao--limpar-filtros')?.addEventListener('click', () => {
    let pais = <HTMLSelectElement>document.querySelector('#busca-por-polos__select--pais');
    let estado = <HTMLSelectElement>document.querySelector('#busca-por-polos__select--estado');
    let cidade = <HTMLSelectElement>document.querySelector('#busca-por-polos__select--cidade');
    let polo = <HTMLSelectElement>document.querySelector('#busca-por-polos__select--polo');
    let geolocalizacao = <HTMLInputElement>document.querySelector('.busca-por-polos__geolocalizacao');
    let geolocalizacaoResultado = document.querySelector('.busca-por-polos__geolocalizacao-resultado');

    // Remover loaders
    document.querySelectorAll('.busca-por-polos__loader--select').forEach((elemento) => {
      elemento.classList.remove('busca-por-polos__loader--ativo');
    });
    document.querySelector('.busca-por-polos__loader--geolocalizacao')?.classList.remove('busca-por-polos__loader--ativo');

    // Ajustar selects
    pais?.parentElement?.classList.remove('busca-por-polos__grupo-campo--oculto');
    estado?.parentElement?.classList.remove('busca-por-polos__grupo-campo--oculto');
    cidade?.parentElement?.classList.remove('busca-por-polos__grupo-campo--oculto');
    polo?.parentElement?.classList.remove('busca-por-polos__grupo-campo--oculto');

    estado?.parentElement?.classList.add('busca-por-polos__grupo-campo--desabilitado');
    cidade?.parentElement?.classList.add('busca-por-polos__grupo-campo--desabilitado');
    polo?.parentElement?.classList.add('busca-por-polos__grupo-campo--desabilitado');

    pais.value = '';
    estado.value = '';
    cidade.value = '';
    polo.value = '';

    // Ajustar geolocalização
    geolocalizacao.checked = false;
    geolocalizacaoResultado?.classList.remove('busca-por-polos__geolocalizacao-resultado--ativo');

    // Desabilitar botão
    document.querySelector('.busca-por-polos__botao--primario')?.classList.add('busca-por-polos__botao--inativo');
  });
}

const buscaPorPolosGeolocalizacao = (posicao) => {
  let pais = <HTMLSelectElement>document.querySelector('#busca-por-polos__select--pais');
  let estado = <HTMLSelectElement>document.querySelector('#busca-por-polos__select--estado');
  let cidade = <HTMLSelectElement>document.querySelector('#busca-por-polos__select--cidade');
  let polo = <HTMLSelectElement>document.querySelector('#busca-por-polos__select--polo');
  let loaderPolo = <HTMLSelectElement>document.querySelector('#busca-por-polos__select--cidade')?.parentElement?.nextElementSibling;
  let geolocalizacao = <HTMLInputElement>document.querySelector('.busca-por-polos__geolocalizacao');
  let nonce = <HTMLInputElement>document.querySelector('#busca-por-polos__action--get-polos-data');
  let cidadeGeolocalizacao = <HTMLElement>document.querySelector('.busca-por-polos__geolocalizacao-resultado');

  if (geolocalizacao.checked) {
    pais?.parentElement?.classList.add('busca-por-polos__grupo-campo--oculto');
    estado?.parentElement?.classList.add('busca-por-polos__grupo-campo--oculto');
    cidade?.parentElement?.classList.add('busca-por-polos__grupo-campo--oculto');
    polo?.parentElement?.classList.add('busca-por-polos__grupo-campo--oculto');
    loaderPolo.classList.add('busca-por-polos__loader--ativo');

    polo.value = '';
    buscaPorPoloValidacaoCampos();

    let dados = new FormData();
    dados.append('action', 'getPolosData');
    dados.append('lat', posicao.coords.latitude);
    dados.append('lng', posicao.coords.longitude);
    dados.append('nonce', nonce.value);

    CreateAjax(dados).then((retorno) => {
      let nomeCidadeGeolocalizacao = <HTMLElement>document.querySelector('.busca-por-polos__geolocalizacao-local');

      IncluirOptionSelect(
        polo,
        retorno,
        'slug',
        'nome',
        'busca-por-polos__grupo-campo--oculto'
      );

      nomeCidadeGeolocalizacao.textContent = retorno[0]['cidade'] + '/' + retorno[0]['uf'];
      cidadeGeolocalizacao.classList.add('busca-por-polos__geolocalizacao-resultado--ativo');

      loaderPolo.classList.remove('busca-por-polos__loader--ativo');

    });

    polo?.parentElement?.classList.remove('busca-por-polos__grupo-campo--desabilitado');

    return;
  }

  pais?.parentElement?.classList.remove('busca-por-polos__grupo-campo--oculto');
  estado?.parentElement?.classList.remove('busca-por-polos__grupo-campo--oculto');
  cidade?.parentElement?.classList.remove('busca-por-polos__grupo-campo--oculto');
  polo?.parentElement?.classList.remove('busca-por-polos__grupo-campo--oculto');

  pais.value = '';
  estado.value = '';
  cidade.value = '';
  polo.value = '';
  buscaPorPoloValidacaoCampos();

  estado?.parentElement?.classList.add('busca-por-polos__grupo-campo--desabilitado');
  cidade?.parentElement?.classList.add('busca-por-polos__grupo-campo--desabilitado');
  polo?.parentElement?.classList.add('busca-por-polos__grupo-campo--desabilitado');

  cidadeGeolocalizacao.classList.remove('busca-por-polos__geolocalizacao-resultado--ativo');

  loaderPolo.classList.remove('busca-por-polos__loader--ativo');
}

const buscaPorPoloValidacaoCampos = () => {
  let pais = <HTMLSelectElement>document.querySelector('#busca-por-polos__select--pais');
  let estado = <HTMLSelectElement>document.querySelector('#busca-por-polos__select--estado');
  let cidade = <HTMLSelectElement>document.querySelector('#busca-por-polos__select--cidade');
  let polo = <HTMLSelectElement>document.querySelector('#busca-por-polos__select--polo');
  let botaoPrimario = document.querySelector('.busca-por-polos__botao--primario');

  if (
    pais.parentElement?.classList.contains('busca-por-polos__grupo-campo--oculto')
    && estado.parentElement?.classList.contains('busca-por-polos__grupo-campo--oculto')
    && cidade.parentElement?.classList.contains('busca-por-polos__grupo-campo--oculto')
  ) {
    if (polo.value) {
      botaoPrimario?.classList.remove('busca-por-polos__botao--inativo');
      return;
    }
  } else if (
    pais.value
    && estado.value
    && cidade.value
    && polo.value
  ) {
    botaoPrimario?.classList.remove('busca-por-polos__botao--inativo');
    return;
  }

  botaoPrimario?.classList.add('busca-por-polos__botao--inativo');
  return;
}
export const CreateAjax = async (
  dados: BodyInit | null | undefined,
  dadosInUrl: string = ''
) => {
  const retornoAjax = await fetch(
    `${WpObj.ajaxurl}${dadosInUrl}`,
    {
      method: 'POST',
      credentials: 'same-origin',
      body: dados
    }
  );

  const retorno = await retornoAjax.json();
  return retorno;
}
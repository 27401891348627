import { CreateAjax } from "../../../Utils/createAjax";
import { DadoDeLead } from "../../../Utils/enviaLead";
import { formaDeIngressoSf } from "../../../Utils/formaDeIngressoSf";
import { getCookie } from "../../../Utils/getCookie";
import { SetDadosLeadAtual } from "./pdpSidebarDePara";
import { PdpSidebarValidarCampos } from "./pdpSidebarValidarCampos";

export interface FormaIngresso {
    url: string;
    nome: string;
    aberto: boolean;
    descricao: string;
    urlTarget: string;
    dataProva: string;
    dataInscricao: string;
    valorInscricao: string;
    tipoFormaIngresso: string;
    nomeCampus: string | null;
    urlAdicional: string | null;
    urlTargetAdicional: string | null;
    nomeCampusAdicional: string | null;
}

export const IrParaFichaDeInscricao = async () => {
    let fluxo = sessionStorage.getItem('sidebarPdpFluxoFormulario');
    let identificadorIncrevaSeElemento = <HTMLInputElement>document.querySelector('.sidebar-pdp input[name="identificador-inscreva-se"]');
    let identificadorVerInvestimentoElemento = <HTMLInputElement>document.querySelector('.sidebar-pdp input[name="identificador-ver-investimento"]');
    let identificador: string = identificadorVerInvestimentoElemento.value;

    SetDadosLeadAtual();
    const leadAtual = sessionStorage.getItem('leadAtual') || '[]';
    const dadosLead: DadoDeLead[] = JSON.parse(leadAtual);


    if (sessionStorage.getItem('sidebarPdpFluxoFormulario') == 'VerInvestimento') {
        return;
    }

    let codCurs = <HTMLInputElement>document.querySelector('#codCurs');
    let tipoCurso = window.location.pathname.split('/')[1];
    let idPolo = <HTMLInputElement | undefined>document.querySelector('.sidebar-pdp-tela__campo-select[name="polo"]');
    let idLead = sessionStorage.getItem('leadAtualId') || '';
    let idTurma = <HTMLSelectElement>document.querySelector('select[name="quanto_iniciar"].sidebar-pdp-tela__campo-select');
    let campusElemento = <HTMLElement | null>document.querySelector('.informacoes-rapidas-coluna-tres-item-valor');
    let campus = campusElemento?.dataset?.campus || '';
    let dados = new FormData();

    dados.append('action', 'getDadosFormaIngresso');
    dados.append('tipoCurso', tipoCurso);
    dados.append('idCurso', codCurs.value);
    dados.append('idPolo', idPolo?.value || '%id-polo-url-inscricao%');
    dados.append('idLead', idLead);
    dados.append('idTurma', idTurma?.options[idTurma.selectedIndex].dataset.idTurma || '');
    dados.append('campus', campus);

    CreateAjax(dados).then((dados) => {
        let url = '';
        url = dados[0].url;

        if (url && dados.length <= 1) {
            if (url.includes('#aviseMe')) {
                document.querySelector('.modal-avise')?.classList.remove('disable-modal');
                document.querySelector('.bg_lightbox')?.classList.remove('disable-modal');
                document.querySelector('body')?.classList.add('blockBody');
                return;
            }

            if (url) {
                window.open(url + '&' + getCookie('parsedQS'));
                document.querySelector('.sidebar-pdp-tela-quase-la-form-acoes-proximo')?.setAttribute('href', url + '&' + getCookie('parsedQS'));
                return;
            }

        } else {
            let formaIngressoAtivo = <HTMLSelectElement>document.querySelector('#sidebar-pdp-tela__campo--formas-ingresso');
            let formaIngressoAtivoValor = formaIngressoAtivo.value;
            let dadosFormaIngresso: any[] = JSON.parse(localStorage.dadosFormaIngresso || '[]') || [];
            let urlFormaIngresso: string | undefined = dadosFormaIngresso.find(
                (elemento) => elemento.nome == formaIngressoAtivoValor
            ).url;

            url = urlFormaIngresso || '';
        }

        window.open(url + '&' + getCookie('parsedQS'));
        document.querySelector('.sidebar-pdp-tela-quase-la-form-acoes-proximo')?.setAttribute('href', url + '&' + getCookie('parsedQS'));
    });
}

export const PdpSidebarDadosFormaIngresso = () => {
    const CarregarFormasIngresso = () => {
        let codCurs = <HTMLInputElement>document.querySelector('#codCurs');
        let tipoCurso = window.location.pathname.split('/')[1];
        let idPolo = <HTMLInputElement>document.querySelector('.sidebar-pdp-tela__campo-select[name="polo"]');
        let idLead = sessionStorage.getItem('leadAtualId') || '';
        let grupoSelectFormasIngresso = document.querySelector('#sidebar-pdp-tela__campo--formas-ingresso')?.parentElement;
        let loader = document.querySelector('#sidebar-pdp-tela__campo--formas-ingresso')?.parentElement?.nextElementSibling;
        let idTurma = <HTMLSelectElement>document.querySelector('select[name="informacoes-rapidas__data-inicio"]');
        let campusElemento = <HTMLElement | null>document.querySelector('.informacoes-rapidas-coluna-tres-item-valor');
        let campus = campusElemento?.dataset?.campus || '';

        loader?.classList.add('sidebar-pdp-tela__select-loader--ativo');
        grupoSelectFormasIngresso?.classList.add('sidebar-pdp-tela__select--oculto');

        let dados = new FormData();
        dados.append('action', 'getDadosFormaIngresso');
        dados.append('tipoCurso', tipoCurso);
        dados.append('idCurso', codCurs.value);
        dados.append('idPolo', idPolo?.value || '%id-polo-url-inscricao%');
        dados.append('idLead', idLead);
        dados.append('idTurma', idTurma?.options[idTurma.selectedIndex].dataset.idTurma || '');
        dados.append('campus', campus);

        let selectElemento = <HTMLElement | null>document.querySelector('select#sidebar-pdp-tela__campo--formas-ingresso');
        selectElemento && (selectElemento.textContent = '');

        let optionDisabled = document.createElement('option');
        optionDisabled.setAttribute('disabled', 'true');
        optionDisabled.setAttribute('selected', 'true');

        selectElemento?.appendChild(optionDisabled);
        CreateAjax(dados).then((dados) => {
            localStorage.setItem('dadosFormaIngresso', JSON.stringify(dados));

            if (dados.forEach) {
                dados.forEach((formaIngresso: any) => {
                    let option = document.createElement('option');
                    option.setAttribute('value', formaIngresso.nome);
                    option.textContent = formaIngresso.nome;
                    selectElemento?.appendChild(option);
                });
            }

            loader?.classList.remove('sidebar-pdp-tela__select-loader--ativo');
            grupoSelectFormasIngresso?.classList.remove('sidebar-pdp-tela__select--oculto');
        });
    }

    CarregarFormasIngresso();

    document.querySelector('select#sidebar-pdp-tela__campo--formas-ingresso')?.addEventListener('change', () => {
        let tituloElemento = <HTMLElement>document.querySelector('.sidebar-pdp-tela-formas-ingresso__titulo');
        let descricaoElemento = <HTMLElement>document.querySelector('.sidebar-pdp-tela-formas-ingresso__descricao');
        let dataInscricaoElemento = <HTMLElement>document.querySelector('.sidebar-pdp-tela-formas-ingresso__informacao--data-inscricao');
        let dataProvaElemento = <HTMLElement>document.querySelector('.sidebar-pdp-tela-formas-ingresso__informacao--data-prova');
        let valorInscricaoElemento = <HTMLElement>document.querySelector('.sidebar-pdp-tela-formas-ingresso__informacao--valor-inscricao');
        let formasIngresso: FormaIngresso[] = JSON.parse(localStorage.getItem('dadosFormaIngresso') ?? '[]');

        // Pegando os detalhes da forma de ingresso para popular o front com as informações
        let formaDeIngresso: FormaIngresso = formasIngresso[0];

        formasIngresso.forEach((formaIngresso) => {
            let selectElemento = <HTMLSelectElement>document
                .querySelector('select#sidebar-pdp-tela__campo--formas-ingresso');

            if (formaIngresso.nome == selectElemento.value) {
                formaDeIngresso = formaIngresso;
            }
        });

        // Visibilidade da forma de ingresso
        let formasIngressoDetalhes = <HTMLElement>document.querySelector('.sidebar-pdp-tela-formas-ingresso__detalhes');
        let formasIngressoSkeleton = <HTMLElement>document.querySelector('.sidebar-pdp-tela-formas-ingresso__skeleton');

        formasIngressoDetalhes.classList.remove('sidebar-pdp-tela-formas-ingresso__detalhes--ativo');
        formasIngressoSkeleton.style.display = 'block';

        let displayFormasIngresso = setTimeout(() => {
            formasIngressoSkeleton.style.display = 'none';
            formasIngressoDetalhes.classList.add('sidebar-pdp-tela-formas-ingresso__detalhes--ativo');
            PdpSidebarValidarCampos('formas-ingresso');

            clearTimeout(displayFormasIngresso);
        }, 1000);

        // Populando detalhes da forma de ingresso
        tituloElemento.textContent = formaDeIngresso.nome;
        descricaoElemento.textContent = formaDeIngresso.descricao;
        valorInscricaoElemento.textContent = formaDeIngresso.valorInscricao;

        formaDeIngressoSf();

        const formataDataFormaIngresso = (dataString: string): string => {
            let dataArray = new Date(dataString).toLocaleString().split(',');

            if (
                dataArray[1] == '' ||
                dataArray[1] == undefined ||
                tituloElemento.textContent?.includes('Medicina')
            ) {
                let detalhesInscricao = <NodeListOf<HTMLElement>>document.querySelectorAll(
                    '.sidebar-pdp-tela-formas-ingresso__detalhes .sidebar-pdp-tela-formas-ingresso__container'
                );

                detalhesInscricao.forEach((container) => {
                    container.classList.add('sidebar-pdp-tela-formas-ingresso__container--inativo');
                });

                return '';
            }

            let dataHora = dataArray[1].split(':');
            return `${dataArray[0]} ${dataHora[0]}:${dataHora[1]}`;
        }

        dataProvaElemento.textContent = formaDeIngresso.dataProva == '' ? '' : formataDataFormaIngresso(formaDeIngresso.dataProva);
        dataInscricaoElemento.textContent = formataDataFormaIngresso(formaDeIngresso.dataInscricao);

        let botaoFormasIngresso = <HTMLAnchorElement>document.querySelector('.sidebar-pdp-tela__proximo[data-tela-atual="formas-ingresso"]');
        let botaoQuaseLa = document.querySelector('.sidebar-pdp-tela-quase-la-form-acoes-proximo');

        botaoFormasIngresso?.setAttribute('href', formaDeIngresso.url);
        botaoFormasIngresso?.setAttribute('target', formaDeIngresso.urlTarget);
        botaoQuaseLa?.setAttribute('href', formaDeIngresso.url);
        botaoQuaseLa?.setAttribute('target', '_blank');

        dataProvaElemento.parentElement?.parentElement?.classList.remove('sidebar-pdp-tela-formas-ingresso__container--inativo');

        if (dataProvaElemento.textContent == '') {
            if (
                tituloElemento.textContent?.includes('Medicina') &&
                formaDeIngresso['medicinaDataProva'] !== '' &&
                formaDeIngresso['medicinaDataProva'] !== null
            ) {
                dataProvaElemento.textContent = formaDeIngresso['medicinaDataProva'];
            } else {
                dataProvaElemento.parentElement?.parentElement?.classList.add('sidebar-pdp-tela-formas-ingresso__container--inativo');
            }
        }

        dataInscricaoElemento.parentElement?.parentElement?.classList.remove('sidebar-pdp-tela-formas-ingresso__container--inativo');

        if (dataInscricaoElemento.textContent == '') {
            if (
                tituloElemento.textContent?.includes('Medicina') &&
                formaDeIngresso['medicinaDataFimInscricoes'] !== '' &&
                formaDeIngresso['medicinaDataFimInscricoes'] !== null
            ) {
                dataInscricaoElemento.textContent = formaDeIngresso['medicinaDataFimInscricoes'];
            } else {
                dataInscricaoElemento.parentElement?.parentElement?.classList.add('sidebar-pdp-tela-formas-ingresso__container--inativo');
            }
        }

        if (
            tituloElemento.textContent?.includes('Medicina') &&
            formaDeIngresso['medicinaValorInscricao'] !== '' &&
            formaDeIngresso['medicinaValorInscricao'] !== null
        ) {
            valorInscricaoElemento.textContent = formaDeIngresso['medicinaValorInscricao'] == 0
                ? 'Gratuita'
                : new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
                    .format(formaDeIngresso['medicinaValorInscricao']);

            valorInscricaoElemento.parentElement?.parentElement?.classList
                .remove('sidebar-pdp-tela-formas-ingresso__container--inativo');
        }

        document.querySelector('.sidebar-pdp-tela-formas-ingresso__container--campus')?.remove();

        if (
            formaDeIngresso.urlAdicional != null &&
            formaDeIngresso.urlAdicional != ''
        ) {
            let optionCampus = document.createElement('option');
            optionCampus.setAttribute('value', '1');
            optionCampus.setAttribute('data-url', formaDeIngresso.url);
            optionCampus.setAttribute('data-target', formaDeIngresso.urlTarget);
            optionCampus.textContent = formaDeIngresso.nomeCampus || 'Campus 1';

            let optionCampusAdicional = document.createElement('option');
            optionCampusAdicional.setAttribute('value', '2');
            optionCampusAdicional.setAttribute('data-url', formaDeIngresso.urlAdicional);
            optionCampusAdicional.setAttribute('data-target', formaDeIngresso.urlTargetAdicional || '_self');
            optionCampusAdicional.textContent = formaDeIngresso.nomeCampusAdicional || 'Campus 2';

            let seletorCampus = document.createElement('select');
            seletorCampus.classList.add('sidebar-pdp-tela__campo-select');
            seletorCampus.setAttribute('id', 'sidebar-pdp-tela__campo--campus-formas-ingresso');
            seletorCampus.setAttribute('name', 'campus');
            seletorCampus.setAttribute('required', '');

            seletorCampus.appendChild(optionCampus);
            seletorCampus.appendChild(optionCampusAdicional);

            seletorCampus.addEventListener('change', (evento: Event) => {
                let seletor = <HTMLSelectElement>evento.target;
                let seletorValue: string = seletor.value;

                let valoresElemento = <HTMLOptionElement>document
                    .querySelector('#sidebar-pdp-tela__campo--campus-formas-ingresso option[value="' + seletorValue + '"]');

                let valores = valoresElemento.dataset;

                botaoQuaseLa?.setAttribute('href', valores.url || '');
                botaoFormasIngresso?.setAttribute('href', valores.url || '');
                botaoFormasIngresso?.setAttribute('target', valores.target || '_self');
            });

            let labelCampus = document.createElement('label');
            labelCampus.setAttribute('for', 'sidebar-pdp-tela__campo--campus-formas-ingresso');
            labelCampus.textContent = 'Selecione um Campus';
            labelCampus.classList.add('sidebar-pdp-tela__label-select');

            let divCampus = document.createElement('div');
            divCampus.classList.add('sidebar-pdp-tela__select');
            divCampus.appendChild(seletorCampus);
            divCampus.appendChild(labelCampus);

            let containerCampus = document.createElement('div');
            containerCampus.classList.add('sidebar-pdp-tela-formas-ingresso__container');
            containerCampus.classList.add('sidebar-pdp-tela-formas-ingresso__container--campus');
            containerCampus.appendChild(divCampus);

            let detalhes = <HTMLElement>document
                .querySelector('.sidebar-pdp-tela-formas-ingresso__detalhes');

            detalhes.appendChild(containerCampus);
        }

        let containersDasFormasIngressos = <NodeListOf<HTMLElement>>document
            .querySelectorAll(".sidebar-pdp-tela-formas-ingresso__container");

        if (!formaDeIngresso.aberto) {
            containersDasFormasIngressos.forEach((container) => {
                container.classList.add('sidebar-pdp-tela-formas-ingresso__container--inativo');
            });

            botaoFormasIngresso.textContent = 'Avise-me';
            botaoFormasIngresso.href = '#aviseMe';

        } else {
            botaoFormasIngresso.textContent = 'Avançar';
        }
    });

    document.querySelector(
        '.sidebar-pdp-tela__proximo[data-proxima-tela-inscreva-se="quase-la"]'
    )?.addEventListener('click', async (evento: Event) => {
        evento.preventDefault();
        let botaoClicado = <HTMLAnchorElement>evento.target;

        if (
            !botaoClicado.href.includes('#aviseMe') &&
            botaoClicado.classList.contains('sidebar-pdp-tela__proximo--ativo')
        ) {
            await IrParaFichaDeInscricao();
        }

        if (botaoClicado.href.includes('#aviseMe')) {
            document.querySelector('.modal-avise')?.classList.remove('disable-modal');
            document.querySelector('.bg_lightbox')?.classList.remove('disable-modal');
            document.querySelector('body')?.classList.add('blockBody');
        }
    });
}
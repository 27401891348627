import BannerComBotoes from "./Modulos/BannerComBotoes/bannerComBotoes";
import { BannerFloating } from "./Modulos/BannerFloating/bannerFloating";
import { BuscaPorPolos } from "./Modulos/BuscaPorPolos/buscaPorPolos";
import CalculadoraEnem from "./Modulos/CalculadoraEnem/calculadoraEnem";
import { ContadorDeInteressados } from "./Modulos/ContadorDeInteressados/contadorDeInteressados";
import { DesativarContinuarLendo, StartSliderConteSuaHistoriaSimples } from "./Modulos/ConteSuaHistoriaSimples/conteSuaHistoriaSimples";
import ConteudoPesquisaNovo from "./Modulos/ConteudoPesquisaNovo/conteudoPesquisaNovo";
import { CxIntervencao } from "./Modulos/CxIntervencao/cxIntervencao";
import { CxSliderGaleria } from "./Modulos/CxSliderGaleria/cxSliderGaleria";
import { TextoAbaLateral } from "./Modulos/CxTextoAbaLateral/TextoAbaLateral";
import { CxWhatsAppFloat } from "./Modulos/CxWhatsAppFloat/cxWhatsAppFloat";
import { DuvidasPdp } from "./Modulos/DuvidasPdp/duvidasPdp";
import { Enem } from "./Modulos/Enem/enem";
import { EspacoDoCandidato } from "./Modulos/EspacoDoCandidato/espacoDoCandidato";
import { Footer } from "./Modulos/Footer/footer";
import FormasIngresso from "./Modulos/FormasIngresso/formasIngresso";
import { Header } from "./Modulos/Header/header";
import { ImagemOtimizada } from "./Modulos/ImagemOtimizada/imagemOtimizada";
import { ListaDeBeneficios } from "./Modulos/ListaDeBeneficios/listaDeBeneficios";
import ModalApoio from "./Modulos/ModalApoio/modalApoio";
import { ModalCalculadoraEnemNovo } from "./Modulos/ModalCalculadoraEnemNovo/ModalCalculadoraEnemNovo";
import { Modalidades } from "./Modulos/Modalidades/modalidades";
import ModalProvaTradicional from "./Modulos/ModalProvaTradicional/modalProvaTradicional";
import ModuloFormulario from "./Modulos/ModuloFormulario/moduloFormulario";
import { MosaicoInformacoes } from "./Modulos/MosaicoInformacoes/mosaicoInformacoes";
import { NoticiasEventos } from "./Modulos/NoticiasEventos/noticiasEventos";
import { Pdp } from "./Modulos/Pdp/pdps";
import { AbrirArquivoCampus, IniciarSliderCampus } from "./Modulos/Pdp/Sessoes/Campus/pdpSessaoCampus";
import AbrirVideoCoordenador from "./Modulos/Pdp/Sessoes/Coordenador/pdpSessaoCoordenador";
import LerMaisDiferenciais from "./Modulos/Pdp/Sessoes/Diferenciais/pdpSessaoDiferenciais";
import LerMaisMercadoTrabalho from "./Modulos/Pdp/Sessoes/MercadoTrabalho/pdpSessaoMercadoTrabalho";
import LerMaisObservacoesAdicionais from "./Modulos/Pdp/Sessoes/ObservacoesAdicionais/pdpSessaoObservacoesAdicionais";
import LerMaisSobreCurso from "./Modulos/Pdp/Sessoes/Sobre/pdpSessaoSobre";
import { abrirFecharModalArquivos, mostrarArquivos, verificarInput } from "./Modulos/Pdp/Sessoes/Sobre/pdpSessaoSobreArquivos";
import { PdpSidebarAcoesFormulario } from "./Modulos/Pdp/Sidebar/pdpSidebarAcoesFormulario";
import { PdpSidebarDadosFormaIngresso } from "./Modulos/Pdp/Sidebar/pdpSidebarDadosFormaIngresso";
import { BuscarCurso, MudarTipoCurso } from "./Modulos/PesquisaCurso/pesquisaCurso";
import SliderBoxes from "./Modulos/SliderBoxes/sliderBoxes";
import Tooltip from "./Utils/tooltip";

window.addEventListener('load', () => {
  if (document.querySelector('.footer__modulo')) {
    Footer();
  }

  if (document.querySelector('.header')) {
    Header();
  }

  if (document.querySelector('.imagem-otimizada')) {
    ImagemOtimizada();
  }
});

if (document.querySelector('.formas-ingresso')) {
  FormasIngresso();
}

if (document.querySelector('.pdp-sessao__sobre .quantidade-conteudo')) {
  LerMaisSobreCurso();
}

if (document.querySelector('.modal__video-coordenador')) {
  AbrirVideoCoordenador();
}

if (document.querySelector('.pdp-sessao__mercado-trabalho')) {
  LerMaisMercadoTrabalho();
}

if (document.querySelector('.pdp-sessao__observacoes-adicionais')) {
  LerMaisObservacoesAdicionais();
}

if (document.querySelector('.pdp-sessao__diferenciais')) {
  LerMaisDiferenciais();
}

if (document.querySelector('.pdp-sessao__sobre .documentos-botao')) {
  abrirFecharModalArquivos();
  verificarInput();
  mostrarArquivos();
}

if (document.querySelector('.pdp-sessao__campus')) {
  AbrirArquivoCampus();
}

if (document.querySelector('.slider-boxes')) {
  SliderBoxes();
}

if (document.querySelector('.banner-buttons__slide')) {
  BannerComBotoes();
}

if (document.querySelector('.pesquisa-curso')) {
  BuscarCurso();
  MudarTipoCurso();
}

if (document.querySelector('.conte_sua_historia_simples__modulo')) {
  DesativarContinuarLendo();
}

if (document.querySelector('.modal-prova-antecipada')) {
  ModalProvaTradicional();
}

if (document.querySelector('.tooltip')) {
  Tooltip();
}

if (document.querySelector('.modulo-formulario')) {
  ModuloFormulario();
}

if (document.querySelector('.calculadora-enem')) {
  CalculadoraEnem();
}

if (document.querySelector('#btnIntervencao-open')) {
  CxIntervencao();
}

if (document.querySelector('.btnsfixos-ajuda')) {
  CxWhatsAppFloat();
}

if (document.querySelector('.modalidades')) {
  Modalidades();
}

if (document.querySelector('.modulo-mosaico-informacoes')) {
  MosaicoInformacoes();
}

if (document.querySelector('.sessao-espaco-do-candidato')) {
  EspacoDoCandidato();
}

if (document.querySelector('.modulo-enem')) {
  Enem();
}

if (document.querySelector('.banner.floating')) {
  BannerFloating();
}

if (document.querySelector('#PrincipaisDuvidas.duvidasPdp')) {
  DuvidasPdp();
}

if (document.querySelector('.textoAbaLateral')) {
  TextoAbaLateral();
}

if (document.querySelector('.contador-de-interessados')) {
  ContadorDeInteressados();
}

if (document.querySelector('.busca-por-polos')) {
  BuscaPorPolos();
}

if (document.querySelector('.conte_sua_historia_simples__modulo')) {
  StartSliderConteSuaHistoriaSimples();
}

if (document.querySelector('.slider-galeria__slides')) {
  CxSliderGaleria();
}

if (document.querySelector('.lista-de-beneficios')) {
  ListaDeBeneficios();
}

if (document.querySelector('.modal-apoio')) {
  ModalApoio();
}

if (document.querySelector('.noticias_eventos__modulo')) {
  NoticiasEventos();
}

if (document.querySelector('.pdp-novos-carrinhos')) {
  Pdp();
}

if (document.querySelector('.pdp-sessao__campus')) {
  IniciarSliderCampus();
}

if (document.querySelector('.sidebar-pdp-tela-um-acoes')) {
  PdpSidebarAcoesFormulario();
}

if (
  document.querySelector('.sidebar-pdp-tela__proximo[data-proxima-tela-inscreva-se="quase-la"]')
) {
  PdpSidebarDadosFormaIngresso();
}

if (document.querySelector('.modal-calculadora-enem')) {
  ModalCalculadoraEnemNovo();
}

if (document.querySelector('.menuop')) {
  ConteudoPesquisaNovo();
}
export const pdpSidebarAbrirInvestimentoPresencial = () => {
  if (!document.querySelector('select[name="periodo"].sidebar-pdp-tela__select-turno option:checked')) return;

  let seletorUnidadeElemento = <HTMLSelectElement>document.querySelector('select[name="campus"].sidebar-pdp-tela__campo-select');
  let seletorPeriodoElemento = <HTMLSelectElement>document.querySelector('select[name="periodo"].sidebar-pdp-tela__select-turno');

  let seletorPeriodo = <HTMLInputElement>document.querySelector('select[name="periodo"].sidebar-pdp-tela__select-turno option:checked');
  let seletorUnidade = <HTMLInputElement>document.querySelector('select[name="campus"].sidebar-pdp-tela__campo-select option:checked');

  if (seletorPeriodo.value == '') return;

  let telaInvestimentoGraduacaoCarregando = <HTMLElement>document.querySelector('.sidebar-pdp-tela-investimento-graduacao--carregando');
  let telaInvestimentoGraduacaoPlanos = <HTMLElement>document.querySelector('.sidebar-pdp-tela-investimento-graduacao--planos');

  telaInvestimentoGraduacaoCarregando.style.display = 'grid';
  telaInvestimentoGraduacaoPlanos.style.display = 'none';

  let dadosPlanoGraduacao = <HTMLElement>document.querySelector('#dados-planos-grad-base');
  let hiddenPlanos = dadosPlanoGraduacao.dataset;
  let planos = JSON.parse(hiddenPlanos.planos || '[]');
  let seletorPeriodoDataset = seletorPeriodo.dataset;
  let unidade = seletorUnidade.value.toUpperCase();
  let periodo = seletorPeriodoDataset.persist;

  seletorUnidadeElemento.addEventListener('change', (evento: Event) => {
    let elemento = <any>evento.target;
    let periodos = JSON.parse(elemento.options[elemento.selectedIndex].dataset.periodo);

    let persistArray = {
      "Matutino": 1,
      "Vespertino": 2,
      "Noturno": 3
    };

    seletorPeriodoElemento.innerHTML = '';
    seletorPeriodoElemento.innerHTML = periodos.map((periodo: string, index: number) => {
      return `<option
        value="${periodo}"
        data-persist="${persistArray[periodo] || 4}"
        ${index === 0 ? 'selected' : ''}
      >${periodo}</option>`;
    });

    pdpSidebarAbrirInvestimentoPresencial();
  });

  planos.forEach((plano) => {
    let realBrasil = Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    });

    if (
      periodo === plano.perSist &&
      unidade === plano.campusEstudo
    ) {
      let subtitulo = plano.titulo.split('-')[1];
      let valorBase = realBrasil.format(plano.valorParcela);
      let valor = <string>plano.valorDesconto;
      let desconto = Math.round(100 - (((Number(valor.replace(',', '.')).toFixed(2)) * 100) / Number(plano.valorParcela.replace(',', '.')).toFixed(2)));
      let valorAtraso = plano.valorParcela;

      document.querySelectorAll(`.plano-${plano.codPlano} .plano__conteudo-valor-de`)
        .forEach((item: any) => item.style.display = 'unset');

      document.querySelectorAll(`.plano-${plano.codPlano} .plano__conteudo-valor-para-tooltip-desconto`)
        .forEach((item: any) => item.style.display = 'unset');

      document.querySelectorAll(`.plano-${plano.codPlano} .plano__subtitulo`)
        .forEach((item: any) => item.textContent = subtitulo);

      document.querySelectorAll(`.plano-${plano.codPlano} .plano__conteudo-valor-de`)
        .forEach((item: any) => item.textContent = `de ${valorBase} por`);

      document.querySelectorAll(`.plano-${plano.codPlano} .plano__conteudo-valor-para-texto`)
        .forEach((item: any) => item.textContent = `R$ ${valor}`);

      document.querySelectorAll(`.plano-${plano.codPlano} .plano__conteudo-valor-para-tooltip-desconto text`)
        .forEach((item: any) => item.innerHTML = `-${desconto}<tspan>%</tspan>`);

      if (Number(valor.replace(',', '.')) >= Number(plano.valorParcela.replace(',', '.'))) {

        document.querySelectorAll(`.plano-${plano.codPlano} .plano__conteudo-valor-de`)
          .forEach((item: any) => item.style.display = 'none');

        document.querySelectorAll(`.plano-${plano.codPlano} .plano__conteudo-valor-para-tooltip-desconto`)
          .forEach((item: any) => item.style.display = 'none');
      }

      document.querySelectorAll(`.plano-${plano.codPlano} .plano__conteudo-valor-para-tooltip`)
        .forEach((item: any) => {
          item.dataset.textoTootip += ` Após a data: R$${valorAtraso}`;
        });

      document.querySelectorAll(`.plano-${plano.codPlano} .plano__conteudo-valor-promocao-texto`)
        .forEach((item: any) => {
          item.innerHTML = plano.textoDescritivo;

          if (
            plano.textoDescritivo == '' ||
            plano.textoDescritivo == null
          ) {
            item.innerHTML = item.dataset.texto;
          }
        });

      document.querySelectorAll(`.plano-${plano.codPlano} .plano__conteudo-valor-promocao`)
        .forEach(item => {
          if (plano.condComerciaisAtivo == '1') {
            item.classList.remove('plano__conteudo-valor-promocao--oculto');
          } else {
            item.classList.add('plano__conteudo-valor-promocao--oculto');
          }
        });
    }
  });

  setTimeout(() => {
    let telaInvestimentoCarregando = <HTMLElement>document.querySelector('.sidebar-pdp-tela-investimento-graduacao--carregando');
    let telaInvestimentoPlanos = <HTMLElement>document.querySelector('.sidebar-pdp-tela-investimento-graduacao--planos');
    telaInvestimentoCarregando.style.display = 'none';
    telaInvestimentoPlanos.style.display = 'grid';

    $('.slider-planos').slick({
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      arrows: true,
      dots: true,
      initialSlide: 0,
      adaptiveHeight: true,
      fade: true,
      cssEase: 'linear',
      speed: 500
    });

    $('.slider-planos').on('init', () => {
      let planosDots = <HTMLElement>document.querySelector('.sidebar-pdp-tela-investimento-graduacao--planos .slick-dots');
      let estado = <HTMLElement>document.querySelector('.sidebar-pdp-tela-investimento-graduacao--planos .estado');
      let listaSliders = <HTMLElement>document.querySelector('.pdp-novos-carrinhos .slider-planos .slick-list');
      let slideAtivo = <HTMLElement>document.querySelector('.pdp-novos-carrinhos .slider-planos .slick-list .slick-active');

      listaSliders.style.height = slideAtivo.style.height;
      estado.style.maxWidth = `calc(100% - ${planosDots.offsetWidth}px - 30px)`;
    });
  }, 1000);
}

export const ScriptsGlobais = () => {
  const local = location.hostname;
  var href: string;
  var url: string = local;

  const AtivarBanners = () => {
    const acao = setInterval(() => {
      clearInterval(acao);

      document.addEventListener('DOMContentLoaded', () => {
        let banners: NodeListOf<HTMLElement> = <NodeListOf<HTMLElement>>document.querySelectorAll('.banner');
        let bannersFundoDesk: NodeListOf<HTMLElement> = <NodeListOf<HTMLElement>>document.querySelectorAll('.banner .desk-fundo');
        let bannersFundoMobile: NodeListOf<HTMLElement> = <NodeListOf<HTMLElement>>document.querySelectorAll('.banner .mobile-fundo');
        let header: HTMLElement = <HTMLElement>document.querySelector('.header');

        if (
          banners.length == 1 &&
          bannersFundoDesk.length == 0
        ) {
          header.classList.remove('act');
          banners[0].classList.add('banner-off');
        }

        if (
          screen.width < 991 &&
          banners.length == 1 &&
          bannersFundoMobile.length == 0
        ) {
          header.classList.remove('act');
          banners[0].classList.add('banner-off');
        }
      });
    }, 500)
  }

  AtivarBanners();

  const AtivarModalRedirecionamento = () => {
    const acao = setInterval(() => {
      clearInterval(acao);

      const acionadores: NodeListOf<HTMLAnchorElement> = <NodeListOf<HTMLAnchorElement>>document.querySelectorAll('a:not(.download-button):not(.botao--primario):not(.botao--ir-ate):not(.documentos-modal .link):not(.footer__marca):not(.footer__principais-cursos-graduacao .itens__lista .item):not(.footer__selos-mec .selos__item .imagem):not(.footer__selos-mec .selos__item .botao):not(.modal-redirecionamento .botao-redirect-ead):not(.slider-boxes__slide a):not(.sidebar-pdp-tela-seis-conteudo-cta):not(.botaoInscrevaPdpPos):not(.sidebar-pdp-tela-quase-la-form-acoes-proximo):not(.iconeAcessibilidade ):not(.nossahistoria a.flex-next):not(.nossahistoria a.flex-prev):not(.ver-selos)');

      acionadores.forEach((acionador: HTMLAnchorElement) => {
        let href = acionador.getAttribute('href');

        if (href) {
          if (
            (
              href.match(/cruzeirodosulvirtual.com.br/) &&
              !url.match(/cruzeirodosulvirtual.com.br/) &&
              !url.match(/localhost:8014/) &&
              !href.match(/enemantecipado/) &&
              !url.match(/politica-de-privacidade/) &&
              !acionador.classList.contains('cta-formas-de-ingresso-ead')
            ) ||
            (
              href.match(/www.orientaportal.com.br/) &&
              href.match(/quiz/)
            ) ||
            (
              href.match(/avisememedicina/)
            ) ||
            (
              href.match(/#aviseMeTradicional/)
            ) ||
            (
              href.match(/aviseMeAgendado/)
            ) ||
            (
              href.match(/www.orientaportal.com.br/) &&
              href.match(/conheca/)
            )
          ) {
            acionador.addEventListener('click', (evento: Event) => {
              if (href) {
                if (
                  href.match(/cruzeirodosulvirtual.com.br/) &&
                  !url.match(/cruzeirodosulvirtual.com.br/) &&
                  !url.match(/localhost:8014/) &&
                  !href.match(/enemantecipado/) &&
                  !url.match(/politica-de-privacidade/) &&
                  !acionador.classList.contains('cta-formas-de-ingresso-ead')
                ) {
                  evento.preventDefault();
                  let objetoUtm: HTMLElement = <HTMLElement>document.querySelector('.botao-redirect-ead');
                  let utm: string = <string>objetoUtm.dataset.utm;

                  let botaoRedirectEad: HTMLAnchorElement = <HTMLAnchorElement>document.querySelector('.botao-redirect-ead');
                  botaoRedirectEad.href = href + utm;

                  let modalRedirecionamento: HTMLElement = <HTMLElement>document.querySelector('.modal-redirecionamento');
                  modalRedirecionamento.classList.remove('disable-modal');

                  let bgLightbox: HTMLElement = <HTMLElement>document.querySelector('.bg_lightbox');
                  bgLightbox.classList.remove('disable-modal');

                  let body: HTMLElement = <HTMLElement>document.querySelector('body');
                  body.classList.add('blockBody');

                  return false;
                } else if (
                  href.match(/www.orientaportal.com.br/) &&
                  href.match(/quiz/)
                ) {
                  evento.preventDefault();

                  let modalOrienta: HTMLElement = <HTMLElement>document.querySelector('.modal-orienta');
                  modalOrienta.classList.remove('disable-modal');

                  let bgLightbox: HTMLElement = <HTMLElement>document.querySelector('.bg_lightbox');
                  bgLightbox.classList.remove('disable-modal');

                  let body: HTMLElement = <HTMLElement>document.querySelector('body');
                  body.classList.add('blockBody');

                  return false;
                } else if (href.match(/avisememedicina/)) {
                  evento.preventDefault();

                  let modalAvise: HTMLElement = <HTMLElement>document.querySelector('.modal-avise');
                  modalAvise.classList.remove('disable-modal');

                  let bgLightbox: HTMLElement = <HTMLElement>document.querySelector('.bg_lightbox');
                  bgLightbox.classList.remove('disable-modal');

                  let body: HTMLElement = <HTMLElement>document.querySelector('body');
                  body.classList.add('blockBody');

                  let identificadorAviseMe: HTMLInputElement = <HTMLInputElement>document.querySelector('#identificadorAviseMe');
                  identificadorAviseMe.value = 'avisememedicina';

                  let modalEstude: HTMLElement = <HTMLElement>document.querySelector('#modal-estude');
                  modalEstude.remove();
                } else if (href.match(/#aviseMeTradicional/)) {
                  evento.preventDefault();

                  let modalAvise: HTMLElement = <HTMLElement>document.querySelector('.modal-avise');
                  modalAvise.classList.remove('disable-modal');

                  let bgLightbox: HTMLElement = <HTMLElement>document.querySelector('.bg_lightbox');
                  bgLightbox.classList.remove('disable-modal');

                  let body: HTMLElement = <HTMLElement>document.querySelector('body');
                  body.classList.add('blockBody');

                  let identificadorAviseMe: HTMLInputElement = <HTMLInputElement>document.querySelector('#identificadorAviseMe');
                  identificadorAviseMe.value = 'Avise-me transferência';

                  let modalEstude: HTMLElement = <HTMLElement>document.querySelector('#modal-estude');
                  modalEstude.remove();
                } else if (href.match(/aviseMeAgendado/)) {
                  evento.preventDefault();

                  let modalAvise: HTMLElement = <HTMLElement>document.querySelector('.modal-avise');
                  modalAvise.classList.remove('disable-modal');

                  let bgLightbox: HTMLElement = <HTMLElement>document.querySelector('.bg_lightbox');
                  bgLightbox.classList.remove('disable-modal');

                  let body: HTMLElement = <HTMLElement>document.querySelector('body');
                  body.classList.add('blockBody');

                  let identificadorAviseMe: HTMLInputElement = <HTMLInputElement>document.querySelector('#identificadorAviseMe');
                  identificadorAviseMe.value = 'aviseMeAgendado';

                  let modalEstude: HTMLElement = <HTMLElement>document.querySelector('#modal-estude');
                  modalEstude.remove();
                } else if (
                  href.match(/www.orientaportal.com.br/) &&
                  href.match(/conheca/)
                ) {
                  evento.preventDefault();

                  let modalVisite: HTMLElement = <HTMLElement>document.querySelector('.modal-visite');
                  modalVisite.classList.remove('disable-modal');

                  let bgLightbox: HTMLElement = <HTMLElement>document.querySelector('.bg_lightbox');
                  bgLightbox.classList.remove('disable-modal');

                  let body: HTMLElement = <HTMLElement>document.querySelector('body');
                  body.classList.add('blockBody');

                  return false;
                } else if (href == location.href) {
                  evento.preventDefault();
                  return;
                }
              } else {
                evento.preventDefault();
                return;
              }

            });
          }
        }
      });
    }, 500)
  };

  AtivarModalRedirecionamento();

  const acordeoes: NodeListOf<HTMLElement> = <NodeListOf<HTMLElement>>document.querySelectorAll('.accordion');
  acordeoes.forEach((acordeao: HTMLElement) => {
    acordeao.addEventListener("click", () => {
      let painel: HTMLElement = <HTMLElement>acordeao.nextElementSibling;
      acordeao.classList.toggle("active");

      if (
        painel.style.maxHeight &&
        painel.style.maxHeight != ''
      ) {
        painel.style.maxHeight = '';
        return;
      }

      painel.style.maxHeight = `${painel.scrollHeight}px`;
    });
  });
}
import { CreateAjax } from "../../../Utils/createAjax";
import { PdpSidebarCarregarInformacoesPagamentoPorQuantidadeDiciplinas } from "./pdpSidebarCarregarInformacoesPagamentoPorQuantidadeDiciplinas";
import { PdpSidebarDePara } from "./pdpSidebarDePara";
import { PdpSidebarValidarCampos } from "./pdpSidebarValidarCampos";

export const PdpSidebarAcoesFormulario = () => {
  let id = <HTMLInputElement>document.querySelector('#idFinalizarLeadInvestimento');
  document.querySelector('.sidebar-pdp-tela__proximo[data-proxima-tela-ver-investimento="cinco"]')?.setAttribute('id', id.value);
  document.querySelector('.sidebar-pdp-tela__proximo[data-proxima-tela-ver-investimento="investimento-graduacao"]')?.setAttribute('id', id.value);

  let listaAcionadores = <NodeListOf<HTMLElement>>document.querySelectorAll('.sidebar-pdp-fluxo-formulario');

  listaAcionadores
    .forEach((botao: HTMLElement) => botao
      .addEventListener('click', (evento: Event) => {
        let elemento = <HTMLElement>evento.target;

        if (elemento.getAttribute('href')?.includes('#aviseMe')) {
          return;
        }

        if (elemento.dataset.telaAtual !== 'formas-ingresso') {
          evento.preventDefault();
        }

        if (elemento.dataset.fluxoFormulario) {
          sessionStorage.setItem(
            'sidebarPdpFluxoFormulario',
            elemento.dataset.fluxoFormulario
          );
        } else if (!elemento.classList.contains('sidebar-pdp-tela__voltar')) {
          if (!elemento.classList.contains('fechar')) {
            if (PdpSidebarValidarCampos(elemento.dataset.telaAtual || 'dois')) {
              if (elemento.getAttribute('href') != '#aviseMePos') {
                if (elemento.getAttribute('href') != '#aviseMeTradicional') {
                  PdpSidebarDePara(
                    elemento.dataset.telaAtual || '',
                    elemento.dataset.proximaTelaInscrevaSe || '',
                    elemento.dataset.proximaTelaVerInvestimento || ''
                  );
                }
              }
            }
            return true;
          }

          document.body.style.overflowY = '';
        }

        if (elemento.getAttribute('href') != '#aviseMePos') {
          if (elemento.getAttribute('href') != '#aviseMeTradicional') {
            PdpSidebarDePara(
              elemento.dataset.telaAtual || '',
              elemento.dataset.proximaTelaInscrevaSe || '',
              elemento.dataset.proximaTelaVerInvestimento || ''
            );
          }
        }
      })
    );

  let listaCampos = <NodeListOf<HTMLInputElement | HTMLSelectElement>>document
    .querySelectorAll('.sidebar-pdp-tela input');

  listaCampos
    .forEach((elemento) => {
      let acao = (event: any) => {
        let funcao = setTimeout(() => {
          let botaoTelaAtiva = <HTMLElement>document
            .querySelector('.sidebar-pdp-tela--ativo .sidebar-pdp-fluxo-formulario');

          if (screen.width <= 992) {
            botaoTelaAtiva = <HTMLElement>document
              .querySelector('.sidebar-pdp-tela--ativo:not(.sidebar-pdp-tela-um) .sidebar-pdp-fluxo-formulario');
          }

          let telaAtual = botaoTelaAtiva.dataset.telaAtual || 'dois';
          PdpSidebarValidarCampos(telaAtual, false);

          if (event.key === 'Enter') {
            let botaoProximo = <HTMLElement>document.querySelector('.sidebar-pdp-tela--ativo .sidebar-pdp-tela__proximo');
            if (PdpSidebarValidarCampos(botaoProximo.dataset.telaAtual || 'dois')) {
              PdpSidebarDePara(
                botaoProximo.dataset.telaAtual || '',
                botaoProximo.dataset.proximaTelaInscrevaSe || '',
                botaoProximo.dataset.proximaTelaVerInvestimento || ''
              );
            }
          }

          clearTimeout(funcao);
        }, 100);
      };

      elemento.addEventListener('keydown', (event: any) => acao(event));
      elemento.addEventListener('change', (event: any) => acao(event));
    });

  let listaCamposSelect = <NodeListOf<HTMLInputElement | HTMLSelectElement>>document
    .querySelectorAll('.sidebar-pdp-tela select');

  listaCamposSelect
    .forEach((elemento) => {
      elemento.addEventListener('change', () => {
        let botaoTelaAtiva = <HTMLElement>document
          .querySelector('.sidebar-pdp-tela--ativo .sidebar-pdp-fluxo-formulario');

        if (screen.width <= 992) {
          botaoTelaAtiva = <HTMLElement>document
            .querySelector('.sidebar-pdp-tela--ativo:not(.sidebar-pdp-tela-um) .sidebar-pdp-fluxo-formulario');
        }

        let telaAtual = botaoTelaAtiva.dataset.telaAtual || 'dois';
        PdpSidebarValidarCampos(telaAtual, false);
      })
    });

  let botaoInscrevaSeInvestimento = <NodeListOf<HTMLElement>>document.querySelectorAll('.sidebar-pdp-tela__inscreva-se');
  botaoInscrevaSeInvestimento.forEach((elemento) => {
    elemento.addEventListener('click', () => {
      if (elemento.getAttribute('href') != '#aviseMePos') {
        if (elemento.getAttribute('href') != '#aviseMeTradicional') {

          sessionStorage.setItem('sidebarPdpFluxoFormulario', 'InscrevaSe');

          PdpSidebarDePara(
            elemento.dataset.telaAtual || '',
            elemento.dataset.proximaTelaInscrevaSe || 'dois',
            elemento.dataset.proximaTelaVerInvestimento || 'dois'
          );
        }
      }
    });
  });

  let selectsQuantidadesDisciplinas = <HTMLSelectElement>document.querySelector('select[name="quantidades_disciplinas"].sidebar-pdp-tela__campo-select');
  selectsQuantidadesDisciplinas?.addEventListener('change', () => PdpSidebarCarregarInformacoesPagamentoPorQuantidadeDiciplinas());

  let botaoProximaTelaTres = <NodeListOf<HTMLElement>>document
    .querySelectorAll('.sidebar-pdp-tela__proximo[data-proxima-tela-inscreva-se="tres"], .sidebar-pdp-tela__proximo[data-proxima-tela-ver-investimento="tres"]');

  botaoProximaTelaTres.forEach((botao: HTMLElement) => botao
    .addEventListener('click', () => {
      let elementoPolo = <HTMLSelectElement>document.querySelector('select[name="polo"].sidebar-pdp-tela__campo-select');
      let poloId = elementoPolo.value;

      if (
        !document.querySelector('.sidebar-pdp-tela-tres-polo--ativo') &&
        poloId !== ''
      ) {
        let elementoCurso = <HTMLInputElement>document.querySelector('input#codCurs');
        let cursoId = elementoCurso.value;

        let elementoPost = <HTMLInputElement>document.querySelector('input#postId');
        let postId = elementoPost.value;

        let elementoNonce = <HTMLInputElement>document.querySelector('input#wp_nonce_get_dados_polo');
        let nonce = elementoNonce.value;

        let elementoTipoCurso = <HTMLInputElement>document.querySelector('#tipo_curso');
        let tipoCurso = elementoTipoCurso.value;

        let loading = <HTMLElement>document.querySelector('.sidebar-pdp-tela-tres-polo--loading');
        document.querySelector('.sidebar-pdp-tela-tres-polo--ativo')?.classList.remove('sidebar-pdp-tela-tres-polo--ativo');

        if (sessionStorage.getItem('sidebarPdpFluxoFormulario') === 'InscrevaSe') {
          loading.style.display = 'block';
        }

        $.ajax({
          url: WpObj.ajaxurl,
          method: 'POST',
          data: {
            action: 'getDadosPolo',
            poloSelecionado: poloId,
            cursoid: cursoId,
            postid: postId,
            tipoCurso: tipoCurso,
            nonce: nonce
          }
        }).done((dados) => {
          let polo = JSON.parse(dados);
          let sidebarPdpTelaTresPolo = <HTMLElement>document.querySelector('.sidebar-pdp-tela-tres-polo');
          let sidebarPdpTelaTresPoloNomePolo = <HTMLElement>document.querySelector('.sidebar-pdp-tela-tres-polo__nome-polo');
          let sidebarPdpTelaTresPoloConteudoLocalizacao = <HTMLElement>document.querySelector('.sidebar-pdp-tela-tres-polo__conteudo--localizacao');
          let sidebarPdpTelaTresPoloCertificadora = <HTMLElement>document.querySelector('.sidebar-pdp-tela-tres-polo__certificadora');

          if (sessionStorage.getItem('sidebarPdpFluxoFormulario') === 'InscrevaSe') {
            loading.style.display = 'none';
            sidebarPdpTelaTresPolo.classList.add('sidebar-pdp-tela-tres-polo--ativo');
          }

          sidebarPdpTelaTresPoloNomePolo.textContent = polo.nome;
          sidebarPdpTelaTresPoloConteudoLocalizacao.textContent = `${polo.endereco}, ${polo.numero}, ${polo.bairro}, ${polo.cidade}/${polo.uf}`;
          sidebarPdpTelaTresPoloCertificadora.setAttribute('src', polo.logoCertificadora);
          loading.style.display = 'none';
          sidebarPdpTelaTresPolo.classList.add('sidebar-pdp-tela-tres-polo--ativo');
        });
      }
    }));

  let seletorQuantoIniciar = <HTMLSelectElement>document
    .querySelector("select.sidebar-pdp-tela__campo-select[name='quanto_iniciar']");

  let nonceGetDadosTurmasExtensaoPres = <HTMLInputElement>document
    .querySelector("#wp_nonce_get_turmas_ext_presencial");

  seletorQuantoIniciar?.addEventListener('change', (evento: Event) => {
    let opcao = <HTMLOptionElement>document
      .querySelector(`select.sidebar-pdp-tela__campo-select[name='quanto_iniciar'] option[value='${seletorQuantoIniciar.value}']`);

    let codigoCurso = opcao.dataset.codigoCurso || '';
    let idTurma = opcao.dataset.idTurma || '';
    let nonce = nonceGetDadosTurmasExtensaoPres.value || '';

    let dados = new FormData();
    dados.append('action', 'getDadosTurmasExtensaoPres');
    dados.append('nonce', nonce);
    dados.append('codigoCurso', codigoCurso);
    dados.append('idTurma', idTurma);

    CreateAjax(dados).then((resposta) => {

      let dadosRetorno = resposta;
      let qtdParcelas = <HTMLElement>document.querySelector('.sidebar-pdp-tela-seis-conteudo-valor-parcelas');
      let valorDe = <HTMLElement>document.querySelector('.sidebar-pdp-tela-seis-conteudo-valor-de');
      let valorPara = <HTMLElement>document.querySelector('.sidebar-pdp-tela-seis-conteudo-valor-para-texto');
      let tooltipInfo = <HTMLElement>document.querySelector('.sidebar-pdp-tela-seis-conteudo-valor-para-tooltip');
      let tooltipInfoTxt = <HTMLElement>document.querySelector('.sidebar-pdp-tela-seis-conteudo-valor-para-tooltip-texto');
      let promocaoDisplay = <HTMLElement>document.querySelector('.sidebar-pdp-tela-seis-conteudo-valor-promocao');
      let promocaoTitulo = <HTMLElement>document.querySelector('.sidebar-pdp-tela-seis-conteudo-valor-promocao-titulo h3');
      let descontoSvg = <HTMLElement>document.querySelector('.sidebar-pdp-tela-seis-conteudo-valor-para-tooltip-desconto');
      let descontoSvgTexto = <HTMLElement>document.querySelector('.sidebar-pdp-tela-seis-conteudo-valor-para-tooltip-desconto text');

      if (
        dadosRetorno[0]['valorDe'] == '' ||
        dadosRetorno[0]['valorDe'] == null
      ) {
        valorDe.style.display = 'none';
        descontoSvg.style.display = 'none';
      } else {
        valorDe.innerText = `por R$ ${dadosRetorno[0]['valorDe']}`;
      }

      if (
        dadosRetorno[0]['textoLegal'] == '' ||
        dadosRetorno[0]['textoLegal'] == null
      ) {
        tooltipInfo.style.display = 'none';
      } else {
        tooltipInfoTxt.innerText = `R$ ${dadosRetorno[0]['textoLegal']}`;
      }

      if (
        dadosRetorno[0]['textoDescritivo'] == '' ||
        dadosRetorno[0]['textoDescritivo'] == null
      ) {
        promocaoDisplay.style.display = 'none';
      } else {
        promocaoTitulo.innerText = `${dadosRetorno[0]['textoDescritivo']}`;
      }

      qtdParcelas.innerText = `${dadosRetorno[0]['qtdParcelas']} parcela(s) de `;
      valorPara.innerText = `R$ ${dadosRetorno[0]['valorPara']}`;

      let valorDesconto = dadosRetorno[0]['porcentagemDesconto'] || ((dadosRetorno[0]['valorPara'] * 100) / dadosRetorno[0]['valorDe']);
      descontoSvgTexto.innerText = `-${valorDesconto}<tspan>%</tspan>`;
    });
  });

  let botaoTelaTres = document.querySelector('.sidebar-pdp-tela__proximo[data-proxima-tela-inscreva-se="formas-ingresso"][data-tela-atual="tres"]');

  botaoTelaTres?.addEventListener('click', () => {
    let localStorageFormaIngresso = <string>localStorage.getItem('dadosFormaIngresso');
    let dadosFormaIngresso = JSON.parse(localStorageFormaIngresso);
    let polo = <HTMLSelectElement>document.querySelector('select.sidebar-pdp-tela__campo-select#polo');

    dadosFormaIngresso.forEach((formaIngresso, index) => {
      let urlNova = dadosFormaIngresso[index]['url'].replace('%id-polo-url-inscricao%', polo?.value);

      dadosFormaIngresso[index]['url'] = urlNova;
    });

    localStorage.setItem('dadosFormaIngresso', JSON.stringify(dadosFormaIngresso));
  });
}
